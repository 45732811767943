import { isPlatform } from '@ionic/angular/standalone';
import config from '../../capacitor.config';

export const { appId } = config;
export const isHybrid = isPlatform('hybrid');

export const env = {
  // Auth0 configuration
  production: false,
  audience: 'https://onech.ec',
  domain: 'onecheck.us.auth0.com',
  clientId: 'Qd7BYIiyiu3s3J2aJWVC6cfaNAVHmgNs',
  auth0Domain: 'onecheck.us.auth0.com',
  appId,
  isHybrid,
  protocol: '',
  auth0Callback: '',
  apiUrl: '',
  scope: 'openid profile email',

  // GetStream Values
  stream_api_key: 'yak8xphavyu8',
  // We can set differnt topics for dev / staging / prod in the appropriate env files.
  notification_topic: "DevDecember",

  // Firebase Config
  firebaseConfig: {
    apiKey: "AIzaSyD--qyeyCWER3ENGseZ1oRHGCT4D_ZLKFE",
    authDomain: "aok-messenger.firebaseapp.com",
    projectId: "aok-messenger",
    storageBucket: "aok-messenger.firebasestorage.app",
    messagingSenderId: "597920404932",
    appId: "1:597920404932:web:5d22bbfdd8ec19c2064091",
    measurementId: "G-47T9YKDKGW"
  },

  // Firebase Storage
  storage_location: "gs://aok-messenger.firebasestorage.app/"
};

export const addDependentVars = (env: any) => {
  env.protocol = env.isHybrid ? `${env.appId}://` : `${env.websiteDomain}/`;
  env.auth0Callback =
    env.isHybrid ? `${env.appId}://${env.auth0Domain}/capacitor/${env.appId}/callback` : env.websiteDomain;
  env.apiUrl = `${env.backendUrl}/api`;
};
