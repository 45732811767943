import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.aokapp.msg',
  appName: 'aOK Messenger',
  webDir: '../../dist/apps/aok-app/browser',
  plugins: {
    SplashScreen: {
      showSpinner: false,
      launchAutoHide: false,
    },
    PushNotifications: {
      presentationOptions: ["badge", "sound", "alert"]
    },
    FirebaseMessaging: {
      presentationOptions: ["badge", "sound", "alert"],
    },
  },
};

export default config;
